import ServiceApi from '../ServiceApi';
import SchemePageHandbook from '@/schemes/SchemePageHandbook';
const {handbookApi} = SchemePageHandbook;
/** Сервис для справочника */
export default class ServiceHandbook {
    /**
     * Получить города
     * @static
     * @async
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async getCities(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('catalog', '/catalog/edit/get-cities', {id: 4});
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            actionAfter(dataRequest);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Получить значения свойства
     * @static
     * @async
     * @property {String} property - название свойства
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async getProperty(property, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('catalog', '/catalog/get-properties', {attribute: property});
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            actionAfter(dataRequest);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Счетчики
     * @static
     * @async
     * @property {String} entity - название сущности
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async getCounters(entity, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('catalog', "/catalog/counters", {entity: entity})
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            const {total, modification, cart} = dataRequest
            actionAfter(total, modification, cart);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Получить данные
     * @static
     * @async
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async modifyCartEdit(body, actionAfter = () => {}) {
        try {
            await ServiceApi.post('catalog', "/catalog/modify-cart-edit", body)
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Табличные данные
     * @static
     * @async
     * @property {String} entityName Название сущности
     * @property {String} urlName название ссылки
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async getTableData(entityName, urlName, body, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('catalog', handbookApi[entityName][urlName], body)
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            actionAfter(dataRequest);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Очистить всю корзину
     * @static
     * @async
     * @property {String} entity Название сущности
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async resetAllCart(entity, actionAfter = () => {}) {
        try {
            await ServiceApi.post('catalog', "/catalog/modify-cart-reset-all", {entity: entity})
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Очистить выбранное
     * @static
     * @async
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async resetCart(body, actionAfter = () => {}) {
        try {
            await ServiceApi.post('catalog', "/catalog/modify-cart-reset", body)
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * принять выбранное
     * @static
     * @async
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async applyCart(body, actionAfter = () => {}) {
        try {
            await ServiceApi.post('catalog', "/catalog/modify-cart-apply", body)
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Принять всю корзину
     * @static
     * @async
     * @property {String} entity Название сущности
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async applyAllCart(entity, actionAfter = () => {}) {
        try {
            await ServiceApi.post('catalog', "/catalog/modify-cart-apply-all", {entity: entity})
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * принять выбранное
     * @static
     * @async
     * @property {String} entityName Название сущности
     * @property {Array} ids идентификаторы строк
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async removeEntities(entityName, ids, actionAfter = () => {}) {
        try {
            await ServiceApi.post('catalog', "/catalog/remove", {ids: ids, entity: entityName})
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Редактирование ячейки
     * @static
     * @async
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async editCell(body, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('catalog', "/catalog/edit-cell", body)
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            actionAfter(dataRequest);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Отправка в корзину
     * @static
     * @async
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async sendToCart(body, actionAfter = () => {}) {
        try {
            await ServiceApi.post('catalog', "/catalog/modify-cart-edit", body)
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Отправка в корзину
     * @static
     * @async
     * @property {Object} body - данные запроса
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async markerInfo(markerId, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('catalog', "/catalog/total-list", {marker_id: markerId})
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            actionAfter(dataRequest);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Загрузка файла
     * @public
     * @static
     * @async
     * @param {Object} file - файл
     * @param {String} matchType
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async matchFile(file, matchType, actionAfter = () => {}, actionError = () => {}) {
        const {reqBody = {}, reqConfig = {}}  = this._uploadFileBefore(file, matchType);
        try {
            const res = await ServiceApi.post('catalog', "/match/upload", reqBody, reqConfig)
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            actionAfter(dataRequest);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Проверка задачи мэтчинга
     * @public
     * @static
     * @async
     * @param {Number} taskId - id задачи
     * @param {Function} actionAfter - событие, сработающее после запроса
     * @param {Function} actionError - событие, сработающее при ошибке
     */
    static async checkTask(taskId, actionAfter = () => {}, actionError = () => {}) {
        try {
            const res = await ServiceApi.post('catalog', "/match/check-task", {task_id: taskId})
            const {data = {}} = res;
            const {data: dataRequest = {}} = data;
            actionAfter(dataRequest);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    /**
     * Получить параметры запроса для загрузка файла
     * @private
     * @static
     * @param {Object} file - файл
     * @param {String} matchType
     * @returns {Object}
     */
    static _uploadFileBefore(file, matchType) {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('match_type', matchType);
        const reqConfig = {headers: {'Content-Type': 'multipart/form-data'}};

        const reqParams = {
            reqBody: formData,
            reqConfig
        };

        return reqParams;
    }
}
