<template>
    <div class="handbook-entity" ref="handbook-entity">
        <div class="handbook-entity__panel">
            <NavigationPanel
                :items="computedItems"
                :selected="currentComponent"
                @choose="onSwitch"
            />

            <div class="right-action-panel">
                <BaseButton
                    view="secondary"
                    form="oval"
                    class="action-btn"
                    @click="onExportFreeSides"
                >
                    <div class="action-btn__wrap">
                        <span class="action-btn__text">Экспорт активных сторон</span>
                    </div>
                </BaseButton>

                <BaseButton
                    view="secondary"
                    form="oval"
                    class="action-btn"
                    @click="$router.push({ name: 'condition' })"
                >
                    <div class="action-btn__wrap">
                        <span class="action-btn__text">Массовая замена</span>
                    </div>
                </BaseButton>

                <BaseButton
                    view="secondary"
                    form="oval"
                    class="action-btn"
                    @click="onOpenMatching"
                >
                    <div class="action-btn__wrap">
                        <span class="action-btn__text">Поиск конструкций</span>
                    </div>
                </BaseButton>

                <BaseDropdown
                    v-if="currentComponent === 'ModerationTab'"
                    :items="moderationDropdownItems"
                    :disabled="selectedRows.length === 0"
                    @check="onModerationAction"
                >
                    <template #content>
                        <BaseButton
                            view="secondary"
                            form="oval"
                            :disabled="selectedRows.length === 0"
                            class="action-btn"
                        >
                            <div class="action-btn__wrap">
                                <span class="action-btn__text">Действие</span>
                                <div class="action-btn__icon">
                                    <img src="@/assets/images/icons/arrow-white.svg">
                                </div>
                            </div>
                        </BaseButton>
                    </template>
                </BaseDropdown>

                <BaseDropdown
                    v-if="currentComponent === 'CartTab'"
                    :items="cartDropdownItems"
                    :disabled="selectedRows.length === 0"
                    @check="onCartAction"
                >
                    <template #content>
                        <BaseButton
                            view="secondary"
                            form="oval"
                            :disabled="selectedRows.length === 0"
                            class="action-btn"
                        >
                            <div class="action-btn__wrap">
                                <span class="action-btn__text">Действие</span>
                                <div class="action-btn__icon">
                                    <img src="@/assets/images/icons/arrow-white.svg">
                                </div>
                            </div>
                        </BaseButton>
                    </template>
                </BaseDropdown>
            </div>

        </div>

        <div class="handbook-entity__view">
            <component
                :is="currentComponent"
                :columns="columns"
                :tableData="tableData"
                :isProgressBarLoading="isProgressBarLoading"
                :modifieredCols="modifieredCols"
                class="handbook__page"
                @sort="onSort"
                @filter="onFilter"
                @lazyload="onLazyload"
                @load="onLoad"
                @edit="onSelectModification"
                @clearCart="onClearCart"
                @approveCartChanges="onApproveCartChanges"
                @editCell="onEditCell"
                @selectRow="onSelectRow"
                @entityInfo="onEntityInfo"
            />
        </div>
        <base-modal
            v-show="isInfoOpened"
            @close="closeInfo"
        >
            <template v-slot:modalBody>
                <HanbookInfoBox
                    v-if="isInfoOpened"
                    :currentEntity="currentEntity"
                    :infoColumns="infoColumns"
                    :rowInfo="rowInfo"
                    :sidesInfo="sidesInfo"
                    :infoBoxPreloader="infoBoxPreloader"
                    @onCloseInfo="closeInfo"
                />
            </template>
        </base-modal>
        <base-modal
            v-show="isMatchingOpened"
            @close="closeMatching"
        >
            <template v-slot:modalBody>
                <HanbookMatching
                    :isMatchingOpened="isMatchingOpened"
                    @onCloseMatching="closeMatching"
                />
            </template>
        </base-modal>
    </div>
</template>

<script>
import HanbookInfoBox from "@/components/Handbook/HanbookInfoBox";
import SchemePageHandbook from '@/schemes/SchemePageHandbook';
import BaseButton from '@/components/Base/BaseButton'
import NavigationPanel from '@/components/NavigationPanel'
import ModerationTab from '@/components/HandbookComponents/ModerationTab'
import CartTab from '@/components/HandbookComponents/CartTab'
import AllTab from '@/components/HandbookComponents/AllTab'
import BaseDropdown from '@/components/Base/BaseDropdownItems'
import BaseModal from '@/components/Base/BaseModal';
import ServiceHandbook from "@/services/ServicesHandbook/ServiceHandbook";
import HanbookMatching from "@/components/Handbook/HanbookMatching";

const {propertyColumns, schemeColumns, allTabTitles, schemeInfoColumns} = SchemePageHandbook;

export default {
    name: "HandbookEntity",
    components: {
        BaseButton,
        NavigationPanel,
        ModerationTab,
        CartTab,
        AllTab,
        BaseDropdown,
        BaseModal,
        HanbookInfoBox,
        HanbookMatching
    },
    data() {
        return {
            currentComponent: 'ModerationTab',
            currentTabData: {
                url: ''
            },
            counters: {
                total: 0,
                modifications: 0,
                cart: 0
            },
            columns: schemeColumns[this.currentEntity],
            requestData: {
                perPage: 60,
                pageNumber: 0,
                sortKey: '',
                sortType: '',
                filterData: []
            },
            tableData: [],
            selectedRows: [],
            isProgressBarLoading: false,
            moderationDropdownItems: [
                { id: 'someSave', value: 'Сохранить в справочник' },
                { id: 'someSend', value: 'Отправить на подтверждение' },
                { id: 'someRemove', value: 'Удалить' },
            ],
            cartDropdownItems: [
                { id: 'someReset', value: 'Вернуть на модерацию' },
                { id: 'someSave', value: 'Сохранить в справочник' },
                { id: 'someRemove', value: 'Удалить' },
            ],
            infoColumns: schemeInfoColumns,
            rowInfo: {},
            sidesInfo:[],
            infoBoxPreloader: false,
            isInfoOpened: false,
            editImageLinkSideId: null,
            isMatchingOpened: false
        }
    },
    props: {
        currentEntity: {
            type: String,
            default: ''
        },
        usersData: {
            type: Array,
            default: () => ([])
        },
    },
    watch: {
        /** Следим за изменением фильтра и подтягиваем найдено */
        currentEntity() {
            this.columns = schemeColumns[this.currentEntity]
            this.fetchChangeOptions()
            this.resetData()
            this.getDataFromApi()
            this.fetchCounters()
        },
    },
    computed: {
        /**
         * @returns {Array} массив названий колонок, которые можно редактировать
         */
        modifieredCols() {
            const result = []
            this.columns.forEach(item => {
                if (item.isModifiered) {
                    result.push(item.prop)
                }
            })

            return result
        },
        computedItems() {
            return [
                {
                    id: 'ModerationTab',
                    title: 'Модерация',
                    postscript: this.counters.modifications,
                },
                {
                    id: 'CartTab',
                    title: 'Требует подтверждения',
                    postscript: this.counters.cart,
                },
                {
                    id: 'AllTab',
                    title: allTabTitles[this.currentEntity],
                    postscript: this.counters.total,
                },
            ]
        },
        requestBody() {
            return {
                pagination: {
                    per_page: this.requestData.perPage,
                    page: this.requestData.pageNumber
                },
                sort: {
                    column: this.requestData.sortKey,
                    type: this.requestData.sortType
                },
                filter: this.requestData.filterData
            }
        },
    },
    mounted() {
        this.fetchChangeOptions()
    },
    methods: {
        onSelectRow(identifiers) {
            this.selectedRows = identifiers
        },
        /**
         * Формирую наборы опций для колонок
         */
        fetchChangeOptions() {
            // this.fetchCities();
            let propertyAttributes = propertyColumns[this.currentEntity];
            propertyAttributes.forEach(attribute => {this.fetchProperty(attribute)});
        },
        /**
         * Запрашиваю массив городов с backend
         * и фиксирую массив в соответствующей колонке
         */
        async fetchCities() {
            if(typeof this.columns.find(item => item.prop === 'city_id') === 'undefined'){
                return true;
            }
            ServiceHandbook.getCities((countries) => {
                const columnCity = this.columns.find(item => item.prop === 'city_id')
                columnCity.changeOptions = countries.map(item => {
                    return {
                        id: item.id,
                        value: item.name
                    }
                })
            });
        },
        /**
         * Запрашиваю массив свойств с backend
         * и фиксирую массив в соответствующей колонке
         */
        async fetchProperty(attribute) {
            ServiceHandbook.getProperty(attribute, (properties) => {
                const columnProperty = this.columns.find(item => item.prop === attribute)
                columnProperty.changeOptions = properties.map(item => {
                    return {
                        id: item.id,
                        value: item.name
                    }
                })
            });
        },
        async getDataFromApi() {
            try {
                const urlName = this.currentTabData.urlName

                this.isProgressBarLoading = true
                ServiceHandbook.getTableData(this.currentEntity, urlName, this.requestBody, (data) => {
                    if (this.currentComponent === 'AllTab') {
                        this.appendToTableData(data)
                        this.initAllTab()
                    }
                    if (this.currentComponent === 'ModerationTab') {
                        this.appendToTableData(data)
                        this.transformTableData()
                    }
                    if (this.currentComponent === 'CartTab') {
                        this.appendToTableData(data.rows)
                        this.initCartTab(data)
                    }


                    this.isProgressBarLoading = false
                });


            } catch (err) {
                console.log(err);
            }
        },
        appendToTableData(newTableData){
            newTableData.forEach(rowData => {
                /* Дублирую колонку image_src для вывода ссылки в таблице */
                this.$set(rowData, 'image_link', rowData.image_src)
                this.tableData.push(rowData)
            })
        },
        initAllTab() {
            this.transformTableData()
        },
        /**
         * Метод инициализации CartTab
         */
        initCartTab(response) {
            const {accepted, denied} = response.cart

            // this.tableData = response.rows
            this.transformTableData()

            /* Устанавливаю selectedModifications */
            this.tableData.forEach(row => {
                row.modifications.forEach(modification => {
                    const isSelected = accepted.includes(modification.id)
                    const isCanceled = denied.includes(modification.id)
                    const propName = modification.entity_attribute
                    const optionId = modification.id

                    if (isSelected) {
                        row.selectetModification[propName] = optionId
                    } else if (isCanceled) {
                        row.selectetModification[propName] = '$canceled'
                    }
                })
            })
        },
        /**
         * Добавляю поле selectetModification в каждую строку
         */
        transformTableData() {
            this.tableData.forEach(rowData => {

                /* Формирую данные для tooltip */
                rowData.modifications.forEach(modificate => {
                    const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
                    const timestamp = modificate.info[0].created_at * 1000

                    const day = new Date(timestamp).getDate()
                    const month = new Date(timestamp).getMonth()
                    const year = new Date(timestamp).getFullYear()
                    const info = modificate.info[0];
                    const userId = info.user_id
                    const userData = this.usersData.find(item => item.id == userId)
                    const userMail = userData ? userData.login : ''

                    const body = {
                        date: `${day} ${months[month]} ${year}`,
                        user: userMail,
                        ...modificate
                    }

                    this.$set(modificate, 'contentFormed', body)
                })
                /* Формирую данные для tooltip */

                /* Формирую объект куда буду записывать выделенные значения */
                this.$set(rowData, 'selectetModification', {})

                let modificationsKeys = new Set()

                rowData.modifications.forEach(modificate => {
                    modificationsKeys.add(modificate.entity_attribute)
                })

                modificationsKeys = Array.from(modificationsKeys)

                modificationsKeys.forEach(key => {
                    this.$set(rowData.selectetModification, key, null)
                })
                /* Формирую объект куда буду записывать выделенные значения */
            })
        },
        /**
         * Запрашиваю количество модификаций
         */
        async fetchCounters() {
            ServiceHandbook.getCounters(this.currentEntity, (total, modification, cart) => {
                this.counters.total = total
                this.counters.modifications = modification
                this.counters.cart = cart
            })
        },
        /**
         * Сбрасывает значения по умолчанию
         */
        resetData() {
            this.requestData = {
                perPage: 60,
                pageNumber: 0,
                sortKey: '',
                sortType: '',
                filterData: []
            }
            this.tableData = []
        },
        /**
         * Устанавливаю выделенное значение
         */
        onSelectModification($event) {
            let isSubmit = true;
            const {rowId, propName, optionId, isCanceled} = $event
            const rowData = this.tableData.find(item => item.id === rowId)
            const rowIndex = this.tableData.findIndex(item => item.id === rowId)
            const rowElement = this.$refs['handbook-entity'].querySelector(`.row[data-rowid="${rowIndex}"]`)

            /* Устанавливаю выделенное значение */
            if (isCanceled) {
                rowData.selectetModification[propName] = '$canceled'
            } else {
                rowData.selectetModification[propName] = optionId
            }

            /* если все значения в строке выделенны, то isSubmit останется true */
            for (let item in rowData.selectetModification) {
                if (rowData.selectetModification[item] === null) {
                    isSubmit = false
                }
            }

            /* Если обработанны все модификации */
            if (isSubmit) {
                const body = {
                    accepted: [],
                    denied: []
                }

                for (let propName in rowData.selectetModification) {
                    const value = rowData.selectetModification[propName]

                    if (value === '$canceled') {
                        const modificates = rowData.modifications.filter(item => item.entity_attribute === propName)

                        modificates.forEach(item => {
                            body.denied.push(item.id)
                        })
                    } else {
                        const modificates = rowData.modifications.filter(item => item.entity_attribute === propName)

                        modificates.forEach(item => {
                            if (item.id === value) {
                                body.accepted.push(item.id)
                            } else {
                                body.denied.push(item.id)
                            }
                        })
                    }
                }
                ServiceHandbook.modifyCartEdit(body, () => {
                    if (this.currentComponent === 'ModerationTab') {
                        rowElement.style.display = 'none'
                        this.fetchCounters()
                    }
                })
            }
        },
        /**
         * Сохранить выделенные строки из корзины
         * @param {Array} identifiers - массив содержащий id строк
         */
        async saveSomeRows() {
            const identifiers = this.selectedRows
            /* Если нет выделенных строк */
            if (identifiers.length === 0) { return }

            const body = {
                accepted: [],
                denied: []
            }

            identifiers.forEach(rowId => {
                const rowData = this.tableData.find(item => item.id === rowId)

                /* Если нашли строку */
                if (rowData) {
                    for (let propName in rowData.selectetModification) {
                        const value = rowData.selectetModification[propName]

                        if (value === '$canceled') {
                            const modificates = rowData.modifications.filter(item => item.entity_attribute === propName)

                            modificates.forEach(item => {
                                body.denied.push(item.id)
                            })
                        } else {
                            const modificates = rowData.modifications.filter(item => item.entity_attribute === propName)

                            modificates.forEach(item => {
                                if (item.id === value) {
                                    body.accepted.push(item.id)
                                } else {
                                    body.denied.push(item.id)
                                }
                            })
                        }
                    }
                }
            })

            ServiceHandbook.applyCart(body, () => {
                this.getDataFromApi()
                this.fetchCounters()

                /* Очищаю массив выделенных id */
                this.selectedRows = []
            });
        },
        /**
         * Сбрасывает конструкцию из корзины в модерацию
         */
        async resetSomeRows() {
            const identifiers = this.selectedRows
            /* Если нет выделенных строк */
            if (identifiers.length === 0) { return }

            const ids = []

            identifiers.forEach(rowId => {
                const rowData = this.tableData.find(item => item.id === rowId)

                /* Если нашли строку */
                if (rowData) {
                    rowData.modifications.forEach(item => { ids.push(item.id) })
                }
            })
            ServiceHandbook.resetCart({ids}, () => {
                this.getDataFromApi()
                this.fetchCounters()

                /* Очищаю массив выделенных id */
                this.selectedRows = []
            });
        },
        /**
         * Удаляет конструкцию из базы
         */
        async removeSomeRows() {
            const identifiers = this.selectedRows
            /* Если нет выделенных строк */
            if (identifiers.length === 0) { return }
            ServiceHandbook.removeEntities(this.currentEntity, identifiers, () => {
                this.getDataFromApi()
                this.fetchCounters()

                /* Очищаю массив выделенных id */
                this.selectedRows = []
            });
        },
        /**
         * Сохранить в справочнике без отправления в корзину
         */
        async fastSaveToHandbook() {
            const identifiers = this.selectedRows
            /* Если нет выделенных строк */
            if (identifiers.length === 0) { return }

            const body = {
                accepted: [],
                denied: []
            }

            identifiers.forEach(rowId => {
                const rowData = this.tableData.find(item => item.id === rowId)

                for (let prop in rowData.selectetModification) {
                    /* Объект модификации */
                    const modificationData = rowData.modifications.find(modificate => modificate.entity_attribute === prop)
                    const id = modificationData.id

                    body.accepted.push(id)
                }
            })

            ServiceHandbook.applyCart(body, () => {
                this.getDataFromApi()
                this.fetchCounters()

                /* Очищаю массив выделенных id */
                this.selectedRows = []
            });
        },
        /**
         * Отправить в корзину через выделение строки
         */
        async fastSendToCart() {
            const identifiers = this.selectedRows
            /* Если нет выделенных строк */
            if (identifiers.length === 0) { return }

            const body = {
                accepted: [],
                denied: []
            }

            identifiers.forEach(rowId => {
                const rowData = this.tableData.find(item => item.id === rowId)

                /* Если нашли строку */
                if (rowData) {
                    for (let prop in rowData.selectetModification) {
                        /* Объект модификации */
                        const modificationData = rowData.modifications.find(modificate => modificate.entity_attribute === prop)
                        const id = modificationData.id

                        body.accepted.push(id)
                    }
                }
            })

            ServiceHandbook.sendToCart(body, () => {
                this.getDataFromApi()
                this.fetchCounters()

                /* Очищаю массив выделенных id */
                this.selectedRows = []
            });
        },
        /**
         * событие переключения на тругой таб в NavigationPanel
         * @param {String, Number} componentName
         */
        onSwitch(componentName) {
            this.currentComponent = componentName
            this.selectedRows = []
        },
        onSort($event) {
            const {type, key} = $event
            this.requestData.sortKey = key
            this.requestData.sortType = type

            this.requestData.pageNumber = 0
            this.tableData = [];
            this.getDataFromApi()
        },
        onFilter(filterData) {
            const result = []

            this.requestData.pageNumber = 0
            this.tableData = [];
            for (let item in filterData) {
                result.push({
                    column: item,
                    values: [ filterData[item] ]
                })
            }
            this.requestData.filterData = result

            this.getDataFromApi()
        },
        onLazyload() {
            this.requestData.pageNumber += 1
            this.getDataFromApi()
        },
        onLoad($event) {
            const {urlName} = $event
            // this.currentTabData.url = url
            this.currentTabData.urlName = urlName

            this.resetData()
            this.getDataFromApi()
            this.fetchCounters()
        },
        /**
         * Очистить корзину
         */
        onClearCart() {
            ServiceHandbook.resetAllCart(this.currentEntity, () => {
                this.resetData()
                this.counters.cart = 0
            });
        },
        /**
         * Сохранить все изменения в корзине
         */
        onApproveCartChanges() {
            ServiceHandbook.applyAllCart(this.currentEntity, () => {
                this.resetData()
                this.counters.cart = 0
            });
        },
        /**
         * ручное редактирование ячейки
         * @param {Object}
         */
        onEditCell($event) {
            const {rowId, optionId, optionContent, attributeName} = $event
            const rowData = this.tableData.find(row => row.id === rowId)
            const oldValue = rowData[attributeName]

            /* Если значение не изменилось */
            if (oldValue === optionContent) { return }

            const body = {
                id: rowId,
                attribute: attributeName,
                value: optionId
            }
            ServiceHandbook.editCell(body, (result) => {
                rowData[attributeName] = optionContent
                if(attributeName === 'image_src'){
                    const {photo = {}} = result
                    rowData['image_link'] = optionContent
                    rowData['photo'] = photo
                }
            });
        },
        /**
         * Срабатывает при клике по item в дропдауне на странице корзины
         */
        onCartAction(item) {
            const {id} = item

            const response = confirm('Вы уверены?')
            if (!response) { return }

            if (id === 'someReset') {
                this.resetSomeRows()
                return
            }

            if (id === 'someSave') {
                this.saveSomeRows()
                return
            }

            if (id === 'someRemove') {
                this.removeSomeRows()
                return
            }
        },
        onModerationAction(item) {
            const {id} = item

            const response = confirm('Вы уверены?')
            if (!response) { return }

            if (id === 'someSave') {
                this.fastSaveToHandbook()
                return
            }

            if (id === 'someSend') {
                this.fastSendToCart()
                return
            }

            if (id === 'someRemove') {
                this.removeSomeRows()
                return
            }
        },
        onEntityInfo(rowId){
            this.isInfoOpened = true;
            this.infoBoxPreloader = true;
            this.rowInfo = this.tableData.filter(item => item.id === rowId)[0];
            if(this.currentEntity === 'marker'){
                ServiceHandbook.markerInfo(this.rowInfo.marker_id, (sides) => {
                    this.sidesInfo = sides;
                    this.infoBoxPreloader = false;
                })
            }
        },

        /**
         * событие закрытие модального окна добавления клиента
         */
        closeInfo() {
            this.isInfoOpened = false;
        },
        closeMatching() {
            this.isMatchingOpened = false;
        },
        onOpenMatching(){
            this.isMatchingOpened = true;
        },
        onExportFreeSides(){
            this.$emit("onExportFreeSides");
        }
    }
}
</script>

<style lang="scss" scoped>
.handbook {
    &__page {
        height: 90%;
    }
}
.handbook-entity {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .right-action-panel{
        display: flex;

        button{
            margin-right: 20px;
        }
    }

    .action-btn {
        &__wrap {
            display: flex;
            align-items: center;
        }

        // &__text {}
        &__icon {
            margin-left: 10px;

            img {
                display: block;
                transform: rotate(-90deg);
                width: 10px;
            }
        }
    }

    &__page {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__view {
        position: relative;
        overflow: hidden;
        margin-right: -40px;
        margin-bottom: -40px;
        margin-top: 30px;
        height: 100%;
    }

    &__panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>

<style lang="scss">
// .handbook {
// 	.table {
// 		padding-left: 40px;
// 	}
// }

.titles .cell-country {
    span { width: 100%; text-align: center; }
    svg {display: none;}
}
</style>
