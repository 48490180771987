<template>
	<div class="admin-cell">
		<span
			class="admin-cell__title"
			:class="{'admin-cell__title_empty': !cellValue}"
			:contenteditable="contenteditable && !hasSelect"
			:hidden="contenteditable && hasSelect"
			v-tooltip="cellValue"
			v-clipboard="() => cellValue"
			@dblclick="onDblclick"
			@blur="onBlur"
			@keydown.enter="onFinishEdit"
		>
			{{cellValue ? cellValue : "Нет данных"}}
		</span>

		<BaseSelect
			v-if="contenteditable && hasSelect"
			:options="changeOptions"
			:canSearch="true"
			:open="true"
			@check="changeValue({id: $event.id, content: $event.value})"
			@close="onCloseSelect"
		/>

		<!-- Селект при редактировании через модерации -->
		<div
			v-if="options.length"
			class="admin-cell__panel"
		>
			<div class="admin-cell__select-wrap">
				<UnusualSelect
					class="admin-cell__select"
					:options="options"
					:modifications="modifications"
					:selected="selected"
					@choose="onChoose"
				/>

				<div
					v-if="options.length === 1"
					class="my-tooltip"
				>
					<p class="my-tooltip__content">{{modifications[0].contentFormed.content || ''}}</p>
					<p class="my-tooltip__user">{{modifications[0].contentFormed.user || 'Автор не найден'}}</p>
                    <p class="my-tooltip__date">{{modifications[0].contentFormed.info[0].event || ''}}</p>
                    <p class="my-tooltip__date">{{modifications[0].contentFormed.info[0].subevent || ''}}</p>
					<p class="my-tooltip__date">{{modifications[0].contentFormed.date || ''}}</p>
				</div>
			</div>

			<div
				class="admin-cell__close"
				@click="onCancel"
			>
				<BaseIcon icon="icon-close" size="sm" />
			</div>
		</div>
	</div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'
import UnusualSelect from '@/components/Base/UnusualSelect'
import BaseSelect from '@/components/Base/BaseSelect'

export default {
	name: "AdminCell",
	components: {
		BaseIcon,
		UnusualSelect,
		BaseSelect
	},
	props: {
		options: {
			type: Array,
			default: () => []
			// required: true,
		},
        isContentAlwaysEditable: {
            type: Boolean,
            default: false
        },
		selected: {
			required: true,
		},
		cellValue: {
			required: true
		},
		cellInfo: {
			type: Object,
			default: () => ({})
			// required: true
		},
		modifications: {
			type: Array,
			default: () => []
			// required: true
		},
		changeOptions: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			tabindex: null,
			contenteditable: false
		}
	},
    mounted() {
        if(this.isContentAlwaysEditable){
            this.contenteditable = true;
            this.$nextTick(() => {
                const titleElement = this.$el.querySelector('.admin-cell__title');
                if (titleElement) {
                    titleElement.focus();
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(titleElement);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            });
        }
    },
    computed: {
		hasSelect() {
			return this.changeOptions.length > 0
		},
	},
	methods: {
		/**
		 * Эмичу выбранный option
		 */
		onChoose($event) {
			const body = {
				optionId: $event,
				...this.cellInfo
			}

			this.$emit('choose', body)
		},
		/**
		 * Эмичу отмененный option
		 */
		onCancel() {
			const body = {
				optionId: this.options[0].id,
				isCanceled: true,
				...this.cellInfo
			}

			this.$emit('choose', body)
		},
		/**
		 * Эмичу новое значение
		 */
		onBlur($event) {
			this.tabindex = null
			this.contenteditable = false
			const content = $event.target.textContent

			this.changeValue({
				id: content,
				content: content
			})
		},
		changeValue(data) {
			const {id} = data
			const content = String(data.content).trim()

			this.$emit('change', { id, content });
		},
		/**
		 * Включаю возможность редактирования
		 */
		onDblclick($event) {
			this.contenteditable = true
			this.tabindex = 1

			setTimeout(() => {
				$event.target.focus()
			}, 0)
		},
		/**
		 * Завершаю редактирование при нажатии на "enter"
		 */
		onFinishEdit($event) {
			$event.target.blur()
		},
		onCloseSelect() {
			this.contenteditable = false
		}
	}
}
</script>

<style lang="scss" scoped>
.admin-cell {
	width: 100%;
	max-width: 100%;

	&__title {
		&:focus {
			outline: none;
			border: 1px solid;
			border-radius: 4px;
			padding: 6px;
		}

		&_empty {
			color: #BEC0C7;
		}
	}

	&__panel {
		position: relative;
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	&__select-wrap {
		position: relative;
		max-width: calc(100% - 24px);

		// &:hover {
		// 	.my-tooltip {
		// 		opacity: 1;
		// 		display: block;
		// 	}
		// }
	}

	&__select {
		width: 100%;

		&:hover + .my-tooltip {
			opacity: 1;
			display: block;
			z-index: 1;
		}
	}

	.my-tooltip {
		transition-delay: .2s;
		transition-duration: .2s;
		position: absolute;
		right: -12px;
		top: 50%;
		transform: translateX(100%) translateY(-50%);
		// z-index: 1;
		background: #000000ee;
		color: #fff;
		// opacity: 0;
		border-radius: 4px;
		padding: 10px;
		display: none;
		box-shadow: 26px 28px 41px #00000085;

		&:before {
			content: "";
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			left: 0px;
			width: 10px;
			height: 10px;
			background: inherit;
		}

		&__content {
			font-size: 10px;
		}
		&__user {
			margin-top: 15px;
			font-weight: 700;
			font-size: 13px;
		}
		&__date {
			color: #ccc;
			font-size: 13px;
			margin-top: 2px;
		}
	}

	&__close {
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #F3F3F3;
		border-radius: 1000px;
		margin-left: 4px;
		cursor: pointer;
		flex-shrink: 0;
	}
}
</style>
